import { OpenAIModelEnum } from '../../constants/openAIModels';

import { TextGenVendorOptions } from './__generated__/useGenerateTextsFromTemplateGenerateAITextMutation.graphql';

const brandVoiceVendorOptions: TextGenVendorOptions = {
  vendorOptions: {
    openaiOptions: {
      model: OpenAIModelEnum.OPENAI_MODEL_GPT_4_O,
    },
  },
};

const nonBrandVoiceVendorOptions: TextGenVendorOptions = {
  vendorOptions: {
    openaiOptions: {
      model: OpenAIModelEnum.OPEN_AI_MODEL_GPT_4_0_MINI,
    },
  },
};

export { brandVoiceVendorOptions, nonBrandVoiceVendorOptions };
