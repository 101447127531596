//Styles copied from multiSelect in picnic to assure consistency in components
import { styled } from '@attentive/picnic';

export const ExclusionInputWrapper = styled('span', {
  display: 'flex',
  flexWrap: 'wrap',
  border: '$borderWidths$borderWidth1 solid $borderInput',
  alignItems: 'center',
  cursor: 'text',
  backgroundColor: '$bgDefault',
  borderRadius: '$radius1',
  width: '100%',
  position: 'relative',
  focusVisible: '$focus',
  '&:hover:not(:focus-visible)': {
    borderColor: '$borderInputHover',
  },
  padding: '$space2 $space3',
  variants: {
    size: {
      small: { minHeight: '$size9', py: '$space0', px: '$space3', fontSize: '$fontSize2' },
      medium: { minHeight: '$size12', py: '$space1', px: '$space4', fontSize: '$fontSize3' },
    },
    state: {
      normal: { borderColor: '$borderInput' },
      error: { '&:not(:focus)': { borderColor: '$borderInputError' } },
    },
    disabled: {
      true: {
        color: '$textDisabled',
        borderColor: '$borderInputDisabled',
        cursor: 'not-allowed',
        '&:hover:not(:focus-visible)': {
          borderColor: '$borderInputDisabled',
        },
      },
      false: {},
    },
  },
  defaultVariant: {
    size: 'medium',
    state: 'normal',
  },
});

export const ExclusionInputTextInput = styled('input', {
  backgroundColor: '$bgDefault',
  boxSizing: 'border-box',
  display: 'inline-flex',
  margin: '$space1',
  // Instead of a fixed width, let the input grow/shrink.
  flex: 1,
  // Optionally, ensure the input doesn’t shrink too far:
  minWidth: '50px',
  flexDirection: 'column',
  justifyContent: 'stretch',
  lineHeight: '$lineHeight2',
  padding: '0',
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: '$textSubdued',
    opacity: 1,
  },
  '&:disabled': {
    color: '$textDisabled',
    borderColor: '$borderInputDisabled',
    cursor: 'not-allowed',
    '&::placeholder': {
      color: '$textDisabled',
    },
  },
  variants: {
    size: {
      small: { fontSize: '$fontSize2' },
      normal: { fontSize: '$fontSize3' },
    },
    state: {
      normal: { borderColor: '$borderInput' },
      error: { '&:not(:focus)': { borderColor: '$borderInputError' } },
    },
  },
  defaultVariants: {
    size: 'normal',
    state: 'normal',
  },
});
