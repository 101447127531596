import React from 'react';

import { Icon, Text, Stack, styled } from '@attentive/picnic';

const ExclusionsErrorCardContainer = styled(Stack, {
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center !important',
  textAlign: 'center',
});

export const ExclusionsErrorCard = () => {
  return (
    <ExclusionsErrorCardContainer direction="vertical" spacing="$space2">
      <Icon name="CircleExclamation" color="warning" size="large" />
      <Text variant="caption">
        <strong>{'This message could not be generated. \n'}</strong>
        Be sure not to exclude commonly used words in sentence construction. If the issue persists,
        contact your onboarding representative for assistance.
      </Text>
    </ExclusionsErrorCardContainer>
  );
};
