import React from 'react';

import { Box, Text, IconCircle, Heading, IconButton } from '@attentive/picnic';

export const BrandVoicePreviewMessagesContainer = ({
  children,
  isLoading = false,
  regenerateMessages,
  subscriberName,
}: {
  children: React.ReactNode;
  isLoading?: boolean;
  regenerateMessages: () => void;
  subscriberName: string;
}) => (
  <Box
    as="section"
    css={{
      background: '$bgDecorative3Default',
      border: '1px solid $bgDecorative3Accent',
      padding: '$space4 $space6 $space6',
      borderRadius: '$radius2',
    }}
  >
    <Box
      css={{
        display: 'flex',
        gap: '$space2',
        alignItems: 'center',
      }}
    >
      <IconCircle color="magic" iconName="Magic" size="extraSmall" />
      <Heading as="h2" variant="sm">
        Brand voice preview
      </Heading>
      <Box css={{ marginLeft: 'auto' }}>
        <IconButton
          variant="subdued"
          iconName="Sync"
          size="small"
          description="Regenerate messages"
          onClick={regenerateMessages}
          disabled={isLoading}
        />
      </Box>
    </Box>
    <Text css={{ margin: '$space2 0 $space4' }}>
      See how your brand voice would sound in different types of SMS messages to a customer named{' '}
      {subscriberName}.
    </Text>
    {children}
  </Box>
);
