import React, { useState, useRef, useMemo } from 'react';

import { Box, Text, TagGroup, MenuItem, Banner } from '@attentive/picnic';

import { ExclusionInputWrapper, ExclusionInputTextInput } from './StyledInputWrapperComponents';
import { ExclusionInputProps } from './types';
import { commonWords } from './utils';

//TODO: Communicate with picnic team to create a generalized component for the  picnic library, if approved reuse it here.
export const ExclusionInput = ({
  placeholder = 'Search',
  selectedValues = [],
  size = 'medium',
  disabled = false,
  onChange,
  maxTokens = 10,
  maxTerms = 10,
  maxPhraseLength = 10,
  css = {},
  errors,
}: ExclusionInputProps) => {
  const [currentInputValue, setCurrentInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const commonWordsUsedSet = useRef<Set<string>>(new Set());

  const localValueSet = useMemo(
    () => (Array.isArray(selectedValues) ? new Set(selectedValues) : selectedValues),
    [selectedValues]
  );

  const lookupSet = useMemo(
    () => new Set(Array.from(localValueSet, (s) => s.toLowerCase())),
    [localValueSet]
  );

  // Converted values for TagGroup.
  const convertedSelectedValuesForTagGroup: MenuItem[] = useMemo(() => {
    const computedCommonWordsSet = new Set<string>();
    const items = Array.from(localValueSet).map((value) => {
      const lowerValue = String(value).toLowerCase();
      if (commonWords.has(lowerValue)) {
        computedCommonWordsSet.add(lowerValue);
      }
      return { label: String(value), value };
    });

    commonWordsUsedSet.current = computedCommonWordsSet;
    return items;
  }, [localValueSet]);

  const commonWordsErrorArray = useMemo(() => {
    return Array.from(localValueSet).map((value) => {
      const lowerValue = String(value).toLowerCase();
      return commonWords.has(lowerValue);
    });
  }, [localValueSet]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCurrentInputValue(value);

    // Validate phrase length and terms size as the user types so typed values are not lost.
    const words = value.trim().split(/\s+/);
    if (words.length > maxPhraseLength) {
      setErrorMessage(`Phrases can only have up to ${maxPhraseLength} words in length`);
    } else {
      setErrorMessage('');
    }

    if (localValueSet.size === maxTerms) {
      setErrorMessage(`You can only enter a maximum of ${maxTerms} words or phrases`);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && currentInputValue.trim() !== '') {
      // If there is an error don't proceed.
      if (errorMessage) {
        e.preventDefault();
        return;
      }

      e.preventDefault();
      const lowerCaseInput = currentInputValue.toLowerCase();
      if (!lookupSet.has(lowerCaseInput)) {
        localValueSet.add(currentInputValue);
        lookupSet.add(lowerCaseInput);
        if (commonWords.has(lowerCaseInput)) {
          commonWordsUsedSet.current.add(lowerCaseInput);
        }

        onChange && onChange(Array.from(localValueSet));
      } else {
        setErrorMessage('This value already exists');
      }
      setCurrentInputValue('');
    }
  };

  const removeTag = (removedValue: string) => {
    const lowerCaseValue = removedValue.toLowerCase();
    localValueSet.delete(removedValue);
    lookupSet.delete(lowerCaseValue);
    commonWordsUsedSet.current.delete(lowerCaseValue);
    if (onChange) {
      if (errorMessage) {
        setErrorMessage('');
      }
      onChange(Array.from(localValueSet));
    }
  };

  const focusOnInput = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current?.focus();
  };

  const wrapperWidth = (inputRef.current?.offsetWidth || 0) as number;
  return (
    <Box>
      <ExclusionInputWrapper
        onClick={focusOnInput}
        ref={inputRef}
        css={css}
        state={errorMessage ? 'error' : 'normal'}
      >
        <TagGroup
          wrapperWidth={wrapperWidth}
          selectedItems={convertedSelectedValuesForTagGroup}
          removeSelectedItem={(removedItem) => removeTag(removedItem.value.toString())}
          maxTokens={maxTokens}
          size={size === 'small' ? 'small' : 'normal'}
          disabled={disabled}
          errors={errors ?? commonWordsErrorArray}
        />
        <ExclusionInputTextInput
          placeholder={placeholder}
          data-testid="tag-input"
          disabled={disabled}
          ref={inputRef}
          value={currentInputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          state={errorMessage ? 'error' : 'normal'}
        />
      </ExclusionInputWrapper>
      <Text variant="caption" color="critical" css={{ marginTop: '$space2' }}>
        {errorMessage}
      </Text>
      {commonWordsUsedSet.current.size > 0 && (
        <Banner variant="warning" css={{ marginTop: '$space2' }}>
          Some of the words you’ve entered as exclusions are essential for constructing meaningful
          sentences (e.g., “a,” “the,” “is,” “and”). Excluding these words may disrupt message
          generation or lead to unintended issues.
        </Banner>
      )}
    </Box>
  );
};
