import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react';

import { LoadingPlaceholder, Stack, Text, styled } from '@attentive/picnic';

import { logger } from '../../logger';
import { useBrandVoiceSettingsForm } from '../BrandVoiceSettingsFormProvider';

import { ExclusionsErrorCard } from './ExclusionsErrorCard';
import { PreviewTemplateKey } from './previewTemplateMap';
import { useDeepCompareEffect } from './utils';

const minHeight = '12rem';

const BrandVoicePreviewMessageCardContainer = styled('div', {
  backgroundColor: '$bgDefault',
  border: '1px solid $bgDecorative3Accent',
  boxShadow: '$shadow1',
  padding: '$space3 $space4 $space2',
  borderRadius: '$radius2',
  whiteSpace: 'pre-wrap',
  width: '100%',
  minHeight,
  flexGrow: 1,
});

export const BrandVoicePreviewMessage = ({ children = '' }: { children?: React.ReactNode }) => (
  <BrandVoicePreviewMessageCardContainer>
    <Text className="brand-voice-preview-text-content">{children}</Text>
  </BrandVoicePreviewMessageCardContainer>
);

const TextPlaceholder = styled(LoadingPlaceholder, {
  width: '100%',
  height: '1rem',
});

export const BrandVoicePreviewMessageLoading = () => (
  <BrandVoicePreviewMessageCardContainer>
    <Stack spacing="$space3" className="brand-voice-preview-text-loading">
      <TextPlaceholder />
      <TextPlaceholder />
      <TextPlaceholder />
      <TextPlaceholder css={{ width: '60%' }} />
    </Stack>
  </BrandVoicePreviewMessageCardContainer>
);

export const BrandVoicePreviewMessageError = () => (
  <BrandVoicePreviewMessageCardContainer>
    <ExclusionsErrorCard />
  </BrandVoicePreviewMessageCardContainer>
);

const parseGeneratedMessage = (text: string) => {
  // removing leading and/or trailing quotes and spaces
  return text.trim().replace(/^[:"']+(\s*)|(\s*)[:"']+$/g, '');
};

export interface BrandVoicePreviewMessageCardHandle {
  refresh: () => void;
}

interface BrandVoicePreviewMessageCardProps {
  previewKey: PreviewTemplateKey;
  generatePreview: (
    previewKey: PreviewTemplateKey
  ) => Promise<ReadonlyArray<{ readonly id: string; readonly text: string }>>;
  generationError: boolean;
  onGenerationError: () => void;
  showExclusionWordsUI: boolean | null;
}

export const BrandVoicePreviewMessageCard = forwardRef<
  BrandVoicePreviewMessageCardHandle,
  BrandVoicePreviewMessageCardProps
>(
  (
    { previewKey, generatePreview, generationError, onGenerationError, showExclusionWordsUI },
    ref
  ) => {
    const [{ brandVoiceSettings }] = useBrandVoiceSettingsForm();

    const containsExclusions = useCallback(
      (message: string) => {
        const exclusionWords = brandVoiceSettings?.exclusionWords || [];
        return exclusionWords.some((word) => message.includes(word));
      },
      [brandVoiceSettings]
    );

    const [message, setMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const generateWithRetry = useCallback(async () => {
      setLoading(true);
      if (generationError) {
        setLoading(false);
        return;
      }

      let latestMessage = '';
      try {
        // First attempt
        let responses = await generatePreview(previewKey);
        latestMessage = parseGeneratedMessage(responses[0].text || '');

        // If no exclusions are enforced or first message is successful
        if (!containsExclusions(latestMessage) || !showExclusionWordsUI) {
          setMessage(latestMessage);
          setLoading(false);
          return;
        }

        // Retry twice if exclusions are enforced.
        for (let attempt = 1; attempt < 3; attempt++) {
          responses = await generatePreview(previewKey);
          latestMessage = parseGeneratedMessage(responses[0].text || '');
          if (!containsExclusions(latestMessage)) {
            setMessage(latestMessage);
            setLoading(false);
            return;
          }
        }
        // After 3 attempts, if no valid message is found and exclusions are enforced, show error.
        setMessage(null);
        setLoading(false);
      } catch (error) {
        if (!generationError) {
          logger.logError(typeof error === 'string' ? new Error(error) : error);
          onGenerationError();
        }
        setLoading(false);
      }
    }, [
      previewKey,
      generatePreview,
      generationError,
      onGenerationError,
      showExclusionWordsUI,
      containsExclusions,
    ]);

    // Refresh function for ref in parent component.
    useImperativeHandle(ref, () => ({
      refresh: () => {
        generateWithRetry();
      },
    }));

    // Generate new messages on mount, and as the form state changes, but make sure to deep compare the object before updating.
    useDeepCompareEffect(() => {
      if (brandVoiceSettings) {
        generateWithRetry();
      }
    }, [brandVoiceSettings]);

    if (generationError) {
      return <BrandVoicePreviewMessageLoading />;
    }
    if (loading) {
      return <BrandVoicePreviewMessageLoading />;
    } else if (message === null) {
      return <BrandVoicePreviewMessageError />;
    }
    return <BrandVoicePreviewMessage>{message}</BrandVoicePreviewMessage>;
  }
);
