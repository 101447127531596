/**
 * @generated SignedSource<<7685bd505a1a31d9b2fbb8f04f65375b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AIBrandVoiceAllCapsUsage = "ALL_CAPS_USAGE_ADAPTIVE" | "ALL_CAPS_USAGE_FREQUENTLY" | "ALL_CAPS_USAGE_NEVER" | "ALL_CAPS_USAGE_SPARINGLY" | "%future added value";
export type AIBrandVoiceEmojiFrequency = "EMOJI_FREQUENCY_ADAPTIVE" | "EMOJI_FREQUENCY_FREQUENTLY" | "EMOJI_FREQUENCY_NEVER" | "EMOJI_FREQUENCY_SPARINGLY" | "%future added value";
export type AIBrandVoiceProductTitleFormatting = "PRODUCT_TITLE_FORMATTING_LOWER_CASE" | "PRODUCT_TITLE_FORMATTING_TITLE_CASE" | "PRODUCT_TITLE_FORMATTING_UPPER_CASE" | "%future added value";
export type AIBrandVoiceUrgency = "URGENCY_ADAPTIVE" | "URGENCY_FREQUENTLY" | "URGENCY_NEVER" | "URGENCY_SPARINGLY" | "%future added value";
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type SetAIBrandVoiceSettingsV2Input = {
  aiBrandVoiceSettings: AIBrandVoiceSettingsV2Input;
  companyId: string;
};
export type AIBrandVoiceSettingsV2Input = {
  allCapsUsage: AIBrandVoiceAllCapsUsage;
  emojiFrequency: AIBrandVoiceEmojiFrequency;
  exclusionWords?: ReadonlyArray<string> | null;
  productTitleFormatting: AIBrandVoiceProductTitleFormatting;
  toneKeywords?: ReadonlyArray<string> | null;
  urgency: AIBrandVoiceUrgency;
};
export type useSaveBrandVoiceSettingsFormMutation$variables = {
  input: SetAIBrandVoiceSettingsV2Input;
};
export type useSaveBrandVoiceSettingsFormMutation$data = {
  readonly setAIBrandVoiceSettingsV2: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
  } | {
    readonly __typename: "SetAIBrandVoiceSettingsV2Success";
    readonly aiBrandVoiceSettings: {
      readonly " $fragmentSpreads": FragmentRefs<"BrandVoiceSettingsFormProviderFragment_AIBrandVoiceSettingsV2">;
    };
    readonly statusText: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type useSaveBrandVoiceSettingsFormMutation$rawResponse = {
  readonly setAIBrandVoiceSettingsV2: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
  } | {
    readonly __typename: "SetAIBrandVoiceSettingsV2Success";
    readonly aiBrandVoiceSettings: {
      readonly allCapsUsage: AIBrandVoiceAllCapsUsage | null;
      readonly emojiFrequency: AIBrandVoiceEmojiFrequency;
      readonly exclusionWords: ReadonlyArray<string>;
      readonly id: string;
      readonly productTitleFormatting: AIBrandVoiceProductTitleFormatting | null;
      readonly toneKeywords: ReadonlyArray<string>;
      readonly urgency: AIBrandVoiceUrgency | null;
    };
    readonly statusText: string;
  } | {
    readonly __typename: string;
  } | null;
};
export type useSaveBrandVoiceSettingsFormMutation = {
  rawResponse: useSaveBrandVoiceSettingsFormMutation$rawResponse;
  response: useSaveBrandVoiceSettingsFormMutation$data;
  variables: useSaveBrandVoiceSettingsFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusText",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "DefaultErrorFailure",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSaveBrandVoiceSettingsFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setAIBrandVoiceSettingsV2",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AIBrandVoiceSettingsV2",
                "kind": "LinkedField",
                "name": "aiBrandVoiceSettings",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BrandVoiceSettingsFormProviderFragment_AIBrandVoiceSettingsV2"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SetAIBrandVoiceSettingsV2Success",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSaveBrandVoiceSettingsFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setAIBrandVoiceSettingsV2",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AIBrandVoiceSettingsV2",
                "kind": "LinkedField",
                "name": "aiBrandVoiceSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "urgency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "toneKeywords",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allCapsUsage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emojiFrequency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productTitleFormatting",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "exclusionWords",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SetAIBrandVoiceSettingsV2Success",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2577c47698e263830f5ce13b5f7c38f9",
    "id": null,
    "metadata": {},
    "name": "useSaveBrandVoiceSettingsFormMutation",
    "operationKind": "mutation",
    "text": "mutation useSaveBrandVoiceSettingsFormMutation(\n  $input: SetAIBrandVoiceSettingsV2Input!\n) {\n  setAIBrandVoiceSettingsV2(input: $input) {\n    __typename\n    ... on SetAIBrandVoiceSettingsV2Success {\n      statusText\n      aiBrandVoiceSettings {\n        ...BrandVoiceSettingsFormProviderFragment_AIBrandVoiceSettingsV2\n      }\n    }\n    ... on DefaultErrorFailure {\n      status\n      message\n      title\n    }\n  }\n}\n\nfragment BrandVoiceSettingsFormProviderFragment_AIBrandVoiceSettingsV2 on AIBrandVoiceSettingsV2 {\n  id\n  urgency\n  toneKeywords\n  allCapsUsage\n  emojiFrequency\n  productTitleFormatting\n  exclusionWords\n}\n"
  }
};
})();

(node as any).hash = "3457a76d7f6957cf796c7f0556fab5fe";

export default node;
